.navbar {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    max-width: 100%;
}

.nav {
    margin-right: 10%;
}

.navlink {
    margin-right: 15px;
}
