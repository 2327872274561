body {
    margin: 0;
    padding: 0;
    /* position: absolute; */
}

.title-container {
    margin-top: 5rem;
    max-width: fit-content;
    
}

.title {
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-size: 3rem;
    letter-spacing: 0.2rem;
    color: white;
}

@media screen and (max-width: 1251px) {
    .title-container {
        margin-top: 5rem;
        /* margin-left: -12rem; */
    }
  }

  @media screen and (max-width: 1187px) {
    .title {
        margin-top: 2rem;
    }
  }

/* PROJECTS */

.projects-page-container {
    max-width: fit-content;
    max-height: fit-content;
    box-sizing: border-box;
    margin-left: 15rem;
    padding: 0;
}

.projects-container {
    display: flex;
    width: 100%;    
}

.left-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 40rem;
    margin-right: 1rem;
}

.carousel {
    max-width: 100%;
}

.right-container {
    display: flex;
    flex-direction: column;
    border-left: 1px solid grey;
    max-width: 100%;
    max-height: 50%;
    padding-left: 2rem;
}

.customArrowPrev {
    position: absolute;
    left: 1rem;
}

.customArrowNext {
    position: absolute;
    right: 1rem;
}

.styled-links {
    color: white;
    text-decoration: underline;
}

.styled-links:hover {
    color: aquamarine;
}

@media screen and (max-width: 1829px) {
    .carousel {
        max-width: 600px;
    }
    .left-container {
        margin: 0;
    }
  }

@media screen and (max-width: 1251px) {
    .projects-page-container {
        padding-left: 0;
        padding-top: 2rem;
        margin-left: 1rem;
        padding: 0;
    }
    .projects-container {
        flex-direction: column;
        max-width: fit-content;
    }
    .right-container {
        padding-left: 0;
    }
}


/* ABOUT */

.scroll-div {
    overflow-y: scroll;
    overflow-x: hidden;
}

.about-page-container {
    max-width: fit-content;
    max-height: fit-content;
    box-sizing: border-box;
    margin-left: 15rem;
    padding: 0;
}

.about-container {
    display: flex;
    max-width: 100%; 
}

.me-img {
    max-height: 20rem;
    border-radius: 10px;
}

.about-left-container {
    display: flex;
    flex-wrap: wrap;
    max-width: fit-content;
}

.about-right-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    max-height: 50%;
    padding-left: 2rem;
}

p {
    font-size: large;
    letter-spacing: 0.05rem;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: rgb(77, 77, 77);
}

::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #b4b4b4;
}

.fe-span {
    font-weight: 600;
    font-size: large;
    letter-spacing: 0.05rem;
}

.be-span {
    font-weight: 600;
    font-size: large;
    letter-spacing: 0.05rem;
}

@media screen and (max-width: 1853px) {
    .me-img {
        max-height: 400px;
    }
}

@media screen and (max-width: 1829px) {
    .about-left-container {
        margin: 0;
    }
  }

@media screen and (max-width: 1251px) {
    .about-page-container {
        padding-left: 0;
        padding-top: 2rem;
        margin-left: 1rem;
        padding: 0;
    }
    .about-container {
        flex-direction: column;
        max-width: fit-content;
    }
    .about-right-container {
        padding-left: 0;
    }
    .me-img {
        height: 100px;
    }
    p, h3 {
        font-size: medium;
        letter-spacing: 0.05rem;
        line-height: 1;
    }
}

/* CONTACT FORM */

.contact-page-container {
    max-width: 40%;
    max-height: fit-content;
    box-sizing: border-box;
    margin-left: 15rem;
    padding: 0;
}

.contact-form-container {
    max-width: 100%;
}

@media screen and (max-width: 1251px) {
    .contact-page-container {
        padding-left: 0;
        padding-top: 2rem;
        margin-left: 1rem;
        padding: 0;
    }
}

@media screen and (max-width: 1050px) {
    .contact-page-container {
        max-width: 80%;
    }
}

@media screen and (max-width: 800px) {
    .contact-page-container {
        max-width: 100%;
    }
}

form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 100%;
    font-size: 1rem;
    padding: 0.5rem;
}

input {
    width: 100%;
    height: 2.2rem;
    padding: 1rem;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
}

input:focus {
    border: 3px solid aquamarine;
}

label {
    color: white;
    margin-top: 1rem;
}

textarea {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    max-height: 10rem;
    min-height: 7rem;
    padding: 1rem;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
}

textarea:focus {
    border: 3px solid aquamarine;
}

input[type="submit"] {
    margin-top: 2rem;
    padding: 0;
    cursor: pointer;
    background: aquamarine;
    color: #343a40;
    border: none;
  }

  input[type="submit"]:hover {
    transform: translateY(-0.25em);
    transition: transform 0.3s ease;
}

/* ALERTS */

.warning-alert {
    max-width: max-content;
}

.warning-alert:hover {
    transform: translateY(-0.25em);
    transition: transform 0.3s ease;
}

.success-alert {
    max-width: max-content;
    background-color: aquamarine;
}

.success-alert:hover {
    transform: translateY(-0.25em);
    transition: transform 0.3s ease;
}

/* FOOTER */

.footer {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    padding: 1rem 0;
  }

  .footer-title {
    margin-top: 0;
    color: whitesmoke;
    font-size: 1rem;
  }

  /* @media screen and (max-width: 685px) {
    .footer {
        justify-content: flex-start;
    }
  } */